<template>
    <div></div>
</template>

<script>
export default {
    name: "index.vue",
    mounted() {
        console.log(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (window.location.href.indexOf('ipad') === -1) {

            }
            this.$router.push({ path: '/ipad/login' })
        } else {
            this.$router.push({ path: '/login' })
        }
    }
}
</script>

<style scoped>

</style>
